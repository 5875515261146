<template>
<v-layout wrap>
    <v-flex xs12>total={{payableAmount}}
         <v-layout wrap py-2>
              
                <v-flex xs12 lg2 pt-8 pt-lg-0 pr-lg-1>
                  <v-btn
                    :disabled="shopckeck == true"
                    block
                    dark
                    color="#766BC0"
                    dense
                    @click="addValues()"
                  >
                    Add <v-icon x-small>mdi-plus</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
       <v-card flat>
       
                <v-layout wrap>
                  <v-flex xs12 pt-1 v-for="(item, i) in itemz" :key="i">
                    <v-layout wrap pt-2>
                     
                      <v-flex xs12 lg2 pt-8 pt-lg-0 pr-lg-1>
                        <v-text-field
                          v-model="item.purchaseAmount"
                          dense
                          label="Price"
                          type="number"
                          solo
                          :rules="[rules.required]"
                          hide-details
                          @input="calcPrice()"
                        ></v-text-field>
                      </v-flex>
                       
                      <v-flex xs12 lg2 pt-4 pt-lg-0 pr-lg-1>
                        <v-text-field
                          v-model="item.quantity"
                          dense
                          type="number"
                          :label="'quantity' + ' ' + ' (' + item.unitType + ')'"
                          solo
                          hide-details
                          :rules="[rules.required]"
                          @input="calcPrice()"
                        ></v-text-field>
                       
                      </v-flex>
<v-flex xs12 lg2 pt-4 pt-lg-0 pr-lg-1>
                        <v-text-field
                          v-model="item.calcTotal"
                          dense
                          type="number"
                          label="Price per item"
                          solo
                          disabled
                          hide-details
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 lg3 pt-2 pt-lg-0>
                        <v-btn
                          block
                          dark
                          style="background-color: #766BC0"
                          dense
                           @click="itemz.splice(i, 1), calcPrice()"
                        >
                          Remove
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
    </v-flex>
</v-layout>
</template>
<script>
export default {
  data() {
    return {
      shopckeck: false,
      purchaseAmount: null,
      quantity: null,
      unitType: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
      itemz: [],
      singleitem: {
        itemId: null,
        purchaseAmount: null,
        quantity: null,
        unitType: "",
      },
      food: "",
      billNo: null,
      totalAmount: null,
      amt: null,
      discount: null,
      bills: [],
      payableAmount: null,
    };
  },
  watch: {
    payableAmount() {
      if (this.totalAmount < this.payableAmount) {
        this.msg = "Total cost and total amount is different!";
        this.snackbar = true;
      }
      if (this.payableAmount < this.totalAmount - this.discount) {
        this.msg = "Amount is much lesser than the total!";
        this.snackbar = true;
      }
    },
    totalAmount() {
      this.payableAmount = this.totalAmount - this.discount;
    },
    discount() {
      this.payableAmount = this.totalAmount - this.discount;
    },
  },
  methods: {
  
    addValues() {
      // singleitem: {
      //     purchaseAmount: null,
      //     quantity: null,
      //     unitType: "",
      //   },
      // this.purchaseAmount = this.singleitem.purchaseAmount;
      // this.quantity = this.singleitem.quantity;
      // this.unitType = this.singleitem.unitType;

       {
        this.singleitem.itemId = this.kindofFoodtype;
        this.singleitem.purchaseAmount = this.purchaseAmount;
        this.singleitem.quantity = this.quantity;
        this.singleitem.unitType = this.unitType;
        this.singleitem.calcTotal = this.quantity*this.purchaseAmount;

        this.calcPrice2(this.purchaseAmount, this.quantity);

        this.addVariant();
      }
    },

    getDocs(event) {
      var myDoc = event.target.files;
      for (let i = 0; i < myDoc.length; i++) {
        this.bills.push(myDoc[i]);
      }
    },

    addVariant() {
      this.itemz.push(JSON.parse(JSON.stringify(this.singleitem)));
      //////
      this.kindofFoodtype = null;
      this.purchaseAmount = null;
      this.quantity = null;
      this.unitType = null;
      // this.amt = null;
    },

    calcPrice2(val1, val2) {
      var totalPrice2 = 0;

      if (val1 && val2) {
        totalPrice2 += val1 * val2;
      }

      this.totalAmount = this.totalAmount + totalPrice2;
      this.payableAmount = this.totalAmount - this.discount;
    },

    calcPrice() {
      var totalPrice = 0;
      var calcTotal = 0;
      for (let i = 0; i < this.itemz.length; i++) {
        if (this.itemz[i].quantity && this.itemz[i].purchaseAmount) {
          totalPrice += this.itemz[i].quantity * this.itemz[i].purchaseAmount;
          this.itemz[i].calcTotal = this.itemz[i].quantity * this.itemz[i].purchaseAmount;
          if(!this.itemz[i].quantity || !this.itemz[i].purchaseAmount) {
            this.itemz[i].calcTotal=0
          }
        }
      }
      this.totalAmount = totalPrice;
      this.amt = calcTotal;

    },
  },
};
</script>

